import RenderBlocks from '@lib/cms/renderBlocks';
import UnifiedSearch from '@components/unified-search';
import Container from '@layout/container';
import Layout from '@layout/index';

// ---------------------------------------------------------

import { unified_search_container } from './styles.module.scss';

// ---------------------------------------------------------

import FlexibleData from '@lib/cms/flexibleData';

// ---------------------------------------------------------

export async function getStaticProps(context) {
  const preview = context.preview || false;
  const previewEnv = context?.previewData?.environment ?? null;
  const data = await FlexibleData.fetch('home', preview, previewEnv);

  return {
    props: { data, preview }
  };
}

// ---------------------------------------------------------

const Home = ({ data, preview }) => {
  return (
    <Layout
      meta={{
        description: data?.metaDescription,
        image: data?.metaImage,
        title: data?.metaTitle
      }}
      preview={preview}
      specializedPageSchema={'websiteSchema'}
    >
      <div className={unified_search_container}>
        <Container
          backgroundImage={{
            height: '515px',
            src: 'https://images.ctfassets.net/wzzep3ntdgx4/1r3LHc4EfE3iank6UEjBMX/617fdc4c3a76dc3dafb584eb3d44a9f8/AdobeStock_462951445.jpeg',
            width: '1542px'
          }}
          priority
          theme="has-white-content-box"
          width="full"
          noOverflow
          noPadding
        >
          <UnifiedSearch />
        </Container>
      </div>
      {data?.blocksCollection && RenderBlocks(data.blocksCollection.items)}
    </Layout>
  );
};

export default Home;
