import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import MiniSearch from 'minisearch';
import OutsideClickHandler from 'react-outside-click-handler';
import useSWR from 'swr';
import { useRouter } from 'next/router';

// ---------------------------------------------------------

import { useDebounce } from '@lib/hooks';
import { timedLocalStorage } from '@lib/utils';
import { querifyLocation } from '@lib/query-utils';
import builderDevelopments from '@src/data/builder-development-info.json';

// ---------------------------------------------------------

import Button from '@components/button';
import Icon from '@components/icon';
import TabSlider from '@components/tab-slider/';
import AdvancedSearch from '@components/advanced-search';
import { categoryInfo, content } from './fixtures';

// ---------------------------------------------------------

import {
  advanced_search_link,
  category_items_partial_street_search,
  category_items,
  category_label,
  hidden,
  no_result,
  pseudo_focus,
  quick_link_container,
  quick_link,
  search_bar,
  search_button,
  search_button_container,
  search_container,
  search_dropdown,
  search_filters,
  tab_container,
  headline_container,
  active
} from './styles.module.scss';
import BuysideWidget from '@components/buyside-widget/component';

// ---------------------------------------------------------

const fetcher = (url) => axios.get(url).then((res) => res.data);

// ---------------------------------------------------------

const getUrlAndQueryParams = (title, url, areaType, desc) => {
  const typesWithQuery = ['schoolDistricts', 'counties', 'places', 'zip'];
  if (areaType === 'builders') return { url: url };
  if (areaType === 'agents') return { url: `https://agent.sibcycline.com${url}` };
  if (areaType === 'zip codes') areaType = 'zip';
  if (areaType === 'school districts') areaType = 'schoolDistricts';

  if (typesWithQuery.includes(areaType)) {
    const locationString = querifyLocation({ areaType, location: title, state: desc });

    return {
      query: { [[areaType]]: locationString, status: ['Active', 'Pending'] },
      url: '/results'
    };
  }
  return { url: url };
};

// ---------------------------------------------------------

const UnifiedSearch = () => {
  const router = useRouter();
  const inputRef = useRef(null);

  const [searchType, setSearchType] = useState('Search');
  const [headline, setHeadline] = useState('Great moves start here.');
  const [showHeadline, setShowHeadline] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [places, setPlaces] = useState([]);
  const [categorizedResults, setCategorizedResults] = useState({});
  const [topResults, setTopResults] = useState([]);
  const [resultsCount, setResultsCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setDropdownStatus] = useState(false);
  const [partialSearchFocus, setPartialSearchFocus] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [quicklink, setQuicklink] = useState('');

  const debouncedSearch = useDebounce(searchTerm, 250);
  const hasValidInput = debouncedSearch.length >= 3;
  const { data, error, isValidating } = useSWR(
    hasValidInput && mounted
      ? '/rest/listings/address-search/' + encodeURIComponent(debouncedSearch.slice(0, 25))
      : null,
    fetcher,
    { dedupingInterval: 5000, fallbackData: [] }
  );
  if (error) console.error('error:', error);

  // ---------------------------------------------------------  useEffects

  /**
   * Fetch data blob on page load and store in localStorage if it doesn't exist yet
   *  This can be lifted up if it will be required in multiple places
   */
  useEffect(() => {
    setMounted(true);
    async function fetchPlaces() {
      if (timedLocalStorage.get('places')) {
        setPlaces(JSON.parse(timedLocalStorage.get('places')));
      } else {
        try {
          const { data } = await axios.get('/rest/listings/places');
          const removedBuilders = data.filter((item) => item.type !== 'Builders'); // remove outdated data
          const maxId = Math.max(...removedBuilders.map((o) => o.id));
          const newBuilders = builderDevelopments.map((entry, index) => ({
            avg: 0,
            count: 0,
            desc: '',
            id: maxId + 1 + index,
            title: entry.builderDevelopmentName,
            type: 'Builders',
            url: '/' + entry.category.toLowerCase() + '/' + entry.slug
          }));
          const newPlaces = [...removedBuilders, ...newBuilders];
          setPlaces(newPlaces);
          timedLocalStorage.set('places', JSON.stringify(newPlaces), 30);
        } catch (err) {
          console.error(err);
        }
      }
    }
    fetchPlaces();
  }, []);

  /**
   * Clear results when input falls to < 3 characters
   */
  useEffect(() => {
    if (!hasValidInput) {
      setCategorizedResults({});
      setResultsCount(0);
    }
  }, [hasValidInput]);

  /**
   * Setup a shortcut to get input focus (for hackers)
   */
  useEffect(() => {
    const focusOnSlashKeyPress = (event) => {
      if (event.code === 'Slash' && event.target.tagName !== 'INPUT') {
        event.preventDefault();
        inputRef.current.focus();
      }
    };
    document.addEventListener('keydown', focusOnSlashKeyPress);
    return () => document.removeEventListener('keydown', focusOnSlashKeyPress);
  }, []);

  /**
   * Initialize and reconfig minisearch on each data input
   */
  useEffect(() => {
    if (isValidating || !mounted) return;

    let miniSearch = new MiniSearch({
      fields: ['title'],
      storeFields: ['title', 'desc', 'type', 'count', 'avg', 'url']
    });
    miniSearch.addAll([...data, ...places]);

    let results =
      (hasValidInput &&
        miniSearch.search(searchTerm, {
          combineWith: 'AND',
          fuzzy: (term) => (term.length > 6 ? 0.2 : null),
          prefix: true
        })) ||
      [];

    setResultsCount(results?.length || 0);

    results.length >= 20 ? setTopResults(results.slice(0, 5)) : setTopResults([]);

    const categorizedResults = {};
    results.forEach((result) => {
      if (categorizedResults[result.type.toLowerCase()] !== undefined) {
        categorizedResults[result.type.toLowerCase()].push(result);
      } else {
        categorizedResults[result.type.toLowerCase()] = [result];
      }
    });

    setCategorizedResults(categorizedResults);
  }, [isValidating]);

  // ---------------------------------------------------------

  const searchTransition = (type) => {
    if (type === searchType) return;
    setShowHeadline(false);

    setSearchType(type);
    if (type === 'Search') {
      setTimeout(() => {
        setHeadline('Great moves start here.');
        setShowHeadline(true);
      }, 250);
    } else if (type === 'Property Estimate') {
      setTimeout(() => {
        setHeadline("What's your home worth?");
        setShowHeadline(true);
      }, 250);
    }
  };

  // ---------------------------------------------------------

  const handleInputChange = (e) => {
    setDropdownStatus(true);
    setPartialSearchFocus(true);
    setSearchTerm(e.target.value);
  };

  const handleInputFieldSelect = () => {
    setDropdownStatus(true);
    setPartialSearchFocus(true);
  };

  const handleItemClick = (url, query) => {
    if (query) {
      router.push({
        pathname: url,
        query
      });
    } else {
      return (location.href = url);
    }
  };

  const handlePartialSearch = () => {
    if (!hasValidInput) return;

    if (resultsCount === 1) {
      const objectKey = Object.keys(categorizedResults)[0];
      const item = categorizedResults[objectKey][0];

      const { url, query } = getUrlAndQueryParams(
        item.title,
        item.url,
        item.type.toLowerCase(),
        item.desc
      );
      handleItemClick(url, query);
      return;
    }

    let statusArray = ['Active', 'Pending'];
    if (resultsCount === 0) statusArray = ['Sold'];

    router.push({
      pathname: '/results',
      query: {
        address: searchTerm,
        status: statusArray
      }
    });
  };

  const handleDropdownClose = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setDropdownStatus(false);
      setPartialSearchFocus(true);
    }
  };

  // ---------------------------------------------------------- Keydown Handlers

  const handleInputFieldKeyDown = (e) => {
    if ((e.key === 'ArrowDown') & hasValidInput) {
      e.preventDefault();
      setDropdownStatus(true);
      setPartialSearchFocus(false);
      e.target.nextSibling.nextSibling.firstChild.focus();
    } else if (e.key === 'Escape') {
      setDropdownStatus(false);
    } else if (e.key === 'Enter') handlePartialSearch();
  };

  const handlePartialSearchKeyDown = (e) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') e.preventDefault();

    switch (e.key) {
      case 'Enter':
        handlePartialSearch();
        break;
      case 'ArrowDown':
        e.target.nextSibling.firstChild.nextSibling.firstChild.focus();
        break;
      case 'ArrowUp':
        inputRef.current.focus();
        break;
      case 'Escape':
        inputRef.current.focus();
        setDropdownStatus(false);
        break;
    }
  };

  const handleItemKeyDown = (e, url, query) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') e.preventDefault();

    const prevTarget =
      e.target?.parentElement?.parentElement?.previousSibling?.children[1]?.lastChild;
    const nextTarget = e.target?.parentElement?.parentElement?.nextSibling?.children[1]?.firstChild;
    const partialSearchTarget = e.target?.parentElement.parentElement.parentElement.firstChild;

    if (e.key === 'Escape') return setDropdownStatus(false);
    else if (e.key === 'Enter') return handleItemClick(url, query);
    else if ((e.key === 'ArrowDown') & hasValidInput) {
      e.target.nextSibling
        ? e.target.nextSibling.focus()
        : nextTarget
        ? nextTarget.focus()
        : partialSearchTarget.focus();
    } else if (e.key === 'ArrowUp') {
      e.target.previousSibling
        ? e.target.previousSibling.focus()
        : prevTarget
        ? prevTarget.focus()
        : partialSearchTarget.focus();
    } else {
      inputRef.current.focus();
      setPartialSearchFocus(true);
    }
  };

  const handleOpenModal = (quickValue) => {
    setQuicklink(quickValue || '');
    setModalIsOpen(true);
  };

  // ---------------------------------------------------------

  const getNoResultsContainer = () => {
    return (
      <div className={no_result}>
        <p>No active listings found.</p>
        <span
          role="button"
          tabIndex="0"
          onKeyDown={handlePartialSearch}
          className={advanced_search_link}
          onClick={handlePartialSearch}
        >
          Search sold properties
        </span>
        <span
          role="button"
          tabIndex="0"
          onKeyDown={() => handleOpenModal()}
          className={advanced_search_link}
          onClick={() => handleOpenModal()}
        >
          Search properties through advanced search
        </span>
      </div>
    );
  };

  const getCategoryContainer = (category) => {
    const isTopSection = category === 'top results';
    const categoryResults = isTopSection ? topResults : categorizedResults[category];

    return (
      <div key={category}>
        <div key={`${isTopSection && 'top'}${category}label`} className={category_label}>
          <Icon type={categoryInfo[category]?.iconType} name={categoryInfo[category]?.iconName} />
          <span>{categoryInfo[category]?.label}</span>
        </div>

        <ul className={category_items}>
          {categoryResults.map((item, index) => {
            const { url, query } = getUrlAndQueryParams(
              item.title,
              item.url,
              item.type.toLowerCase(),
              item.desc
            );
            return (
              <li
                role="menuitem"
                tabIndex="-1"
                key={`${item.id}${index}`}
                onClick={() => handleItemClick(url, query)}
                onKeyDown={(e) => handleItemKeyDown(e, url, query)}
              >
                <a className={resultsCount === 1 ? pseudo_focus : null}>
                  {item.title}{' '}
                  <span>
                    {item.desc} {item.count > 1 ? `${item.count} Listings` : ''}
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const shouldShowNoResults = hasValidInput && !isValidating && resultsCount === 0;
  const shouldShowResults = categorizedResults && hasValidInput;
  const dropdownClasses = classNames(search_dropdown, { [hidden]: !isDropdownOpen });

  return (
    <>
      <div className={search_container} onBlur={handleDropdownClose}>
        <div className={headline_container}>
          <h1 className={classNames({ [active]: showHeadline === true })}>{headline}</h1>
        </div>
        <div className={tab_container}>
          <TabSlider
            items={[{ label: 'Search' }, { label: 'Property Estimate' }]}
            theme="unified"
            setSelectedTabCallback={searchTransition}
          ></TabSlider>
        </div>

        <OutsideClickHandler onOutsideClick={() => setDropdownStatus(false)}>
          <div style={searchType !== 'Search' ? { display: 'none' } : {}} className={search_bar}>
            <input
              ref={inputRef}
              tabIndex="0"
              value={searchTerm}
              onFocus={handleInputFieldSelect}
              onChange={handleInputChange}
              onKeyDown={handleInputFieldKeyDown}
              placeholder="City, Street Address, School District, Agent, ZIP"
            />
            <div className={search_button_container}>
              <button
                className={search_button}
                onClick={handlePartialSearch}
                onKeyDown={handleInputFieldKeyDown}
              >
                <Icon type="actions" name="search" />
              </button>
            </div>

            <div className={dropdownClasses}>
              {shouldShowNoResults ||
                (shouldShowResults && resultsCount > 1 && (
                  <div
                    role="menuitem"
                    tabIndex={-1}
                    onClick={handlePartialSearch}
                    onKeyDown={handlePartialSearchKeyDown}
                    className={category_items_partial_street_search}
                  >
                    <a className={partialSearchFocus ? pseudo_focus : null}>
                      Partial street search: {searchTerm}
                    </a>
                  </div>
                ))}

              {shouldShowNoResults && getNoResultsContainer()}

              {shouldShowResults && (
                <>
                  {topResults.length >= 1 && getCategoryContainer('top results')}

                  {Object.keys(categorizedResults).map((category) => {
                    const categoryItems = categorizedResults[category];
                    return categoryItems?.length < 1 ? null : getCategoryContainer(category);
                  })}
                </>
              )}
            </div>
          </div>
        </OutsideClickHandler>

        <div style={searchType !== 'Property Estimate' ? { display: 'none' } : {}}>
          <BuysideWidget theme="unified search" />
        </div>
      </div>

      <div className={quick_link_container}>
        <div className={search_filters}>
          <Button
            className={quick_link}
            theme="with_icon"
            type="button"
            label="Advanced Search"
            icon="search"
            iconType="filters"
            onClick={() => handleOpenModal()}
          />
          {content.filters.map((filter, index) => {
            if (filter.link) {
              return (
                <Button
                  className={quick_link}
                  theme="with_icon"
                  type="button"
                  {...filter}
                  onClick={() => router.push(filter.link)}
                  key={index}
                />
              );
            }

            return (
              <Button
                className={quick_link}
                theme="with_icon"
                type="button"
                {...filter}
                onClick={() => handleOpenModal(filter.label)}
                url={null}
                key={index}
              />
            );
          })}
          {modalIsOpen && (
            <AdvancedSearch
              isNewSearch={true}
              modalIsOpen={modalIsOpen}
              setModalIsOpen={setModalIsOpen}
              quicklink={quicklink}
              setQuicklink={setQuicklink}
            />
          )}
        </div>
      </div>
    </>
  );
};

UnifiedSearch.propTypes = {};

UnifiedSearch.defaultProps = {};

export default UnifiedSearch;
