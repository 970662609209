// ---------------------------------------------------------

export default {
  inContainer: {
    backgroundImage: {
      height: '515px',
      src: 'https://images.ctfassets.net/wzzep3ntdgx4/1r3LHc4EfE3iank6UEjBMX/617fdc4c3a76dc3dafb584eb3d44a9f8/AdobeStock_462951445.jpeg',
      width: '1542px'
    }
  },
  props: {}
};

// ---------------------------------------------------------

export const content = {
  filters: [
    {
      icon: 'neighborhood',
      label: 'Browse A-Z',
      link: '/browse'
    },
    {
      icon: 'notification',
      label: 'Coming Soon',
      link: '/coming-soon'
    },
    {
      icon: 'school-district',
      label: 'School District'
    },
    {
      forceAnchorTag: true,
      icon: 'price-change',
      label: 'Price Changes',
      link: {
        pathname: '/results',
        query: {
          daysSinceLastPriceChange: '7',
          sortby: 'Price Change',
          status: ['Active', 'Pending']
        }
      }
    },
    {
      forceAnchorTag: true,
      icon: 'new-listing',
      label: 'New Listings',
      link: {
        pathname: '/results',
        query: {
          daysSinceNew: '7',
          sortby: 'Newest Listings',
          status: ['Active', 'Pending']
        }
      }
    },

    {
      forceAnchorTag: true,
      icon: 'open-house',
      label: 'Open Houses',
      link: {
        pathname: '/results',
        query: {
          showOnlyOpenHouses: 'true',
          status: ['Active', 'Pending']
        }
      }
    },
    {
      icon: 'new-construction',
      label: 'New Construction',
      link: {
        pathname: '/results',
        query: {
          showOnlyNewConstructions: 'true',
          status: ['Active', 'Pending']
        }
      }
    }
  ],
  heading: 'Great moves start here',
  search_link: 'Advanced search'
};

export const categoryInfo = {
  addresses: {
    iconName: 'streetview',
    iconType: 'filters',
    label: 'Addresses'
  },
  agents: {
    iconName: 'account',
    iconType: 'general',
    label: 'Agent'
  },
  builders: {
    iconName: 'account',
    iconType: 'general',
    label: 'Builders'
  },
  counties: {
    iconName: 'location',
    iconType: 'filters',
    label: 'County'
  },
  places: {
    iconName: 'neighborhood',
    iconType: 'filters',
    label: 'City/Suburb'
  },
  'school districts': {
    iconName: 'school-district',
    iconType: 'filters',
    label: 'School District'
  },
  'top results': {
    iconName: 'location',
    iconType: 'filters',
    label: 'Top Result'
  },
  'zip codes': {
    iconName: 'open-house',
    iconType: 'filters',
    label: 'Zipcode'
  }
};
